<template>
  <a-form :form="form" @submit="onSubmit" >
    <a-form-item label="Наименование товара">
      <a-input v-decorator="[
          'Name',
          { initialValue: formData.Name,
          rules: [{ required: true, message: 'Пожалуйста заполните Наименование Единицы' }] },
        ]" />
    </a-form-item>

    <a-form-item>
      <a-button type="primary" :loading="loading" @click="onSubmit">
        Сохранить
      </a-button>
      <a-button style="margin-left: 10px;">
        <router-link :to="{ name: 'measurements' }">Отмена</router-link>
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { formatResponseValidatorFields } from '../../helpers';

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'measurement' });
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      formData: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$api.saveMeasurement(this.$router.currentRoute.params, values, () => {
            this.$router.push({ name: 'measurements-list' });
            this.loading = false;
          }, ({ data, status }) => {
            const fields = formatResponseValidatorFields(data, values);
            this.loading = false;
            if (status === 422) {
              this.form.setFields(fields);
            }
          });
        }
        this.loading = true;
      });
    },
    fetch() {
      this.$api.getMeasurement(this.$router.currentRoute.params, false, ({ data }) => {
        this.formData = data.data;
      });
    },
  },
};
</script>
